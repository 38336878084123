import { Component, OnDestroy, OnInit } from '@angular/core';
import { MainService } from './core/services/main.service';
import { Subscription } from 'rxjs';
import { IDomain } from './core/interfaces/IDomain';
import { DomainService } from './core/services/domain.service';
import { Title } from '@angular/platform-browser';
import { AfsService } from './core/services/afs.service';
import { map, filter } from 'rxjs/operators';
import { IPoi } from './core/interfaces/IPoi';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  domainSubscription: Subscription;
  myDomain: IDomain = null;
  poiList: IPoi[] = null;
  ngOnInit(): void {
    this.domainSubscription = this.domainService.domainBehaviorSubject.subscribe((theDomain) => {
      this.myDomain = theDomain;
      if (theDomain && theDomain.name) {
        this.titleService.setTitle(theDomain.name);
        this.getPOIList();
      }
    })
  }
  ngOnDestroy(): void {
    throw new Error("Method not implemented.");
  }
  title = 'WooWoo';
  constructor(private mainService: MainService, private domainService: DomainService, private titleService: Title, private afs:AfsService) {
   
  }
  getPOIList() {
    this.afs.getCollection('poiList', ref => ref.where('domainKey', '==', this.myDomain.key)).snapshotChanges().pipe(map(
      changes => {
        return changes.map(
          a => {
            const data = a.payload.doc.data() as IPoi;
            data.key = a.payload.doc.id;
            return data;
          });

      })).subscribe((poiList) => {
        this.poiList = poiList;
      })
  }
  getHeaderImage(): string{
    let image = 'kotis.png';
    if (this.myDomain && this.myDomain.headerImage) {
      image = this.myDomain.headerImage;
    }
    return 'assets/skylines/' + image;



}
}
