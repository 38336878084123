import { Injectable, Inject } from '@angular/core';
import { AfsService } from './afs.service';
import { WINDOW } from './window.service';
import { map, filter } from 'rxjs/operators';

import { BehaviorSubject } from 'rxjs';
import { IDomain } from '../interfaces/IDomain';

@Injectable({
  providedIn: 'root'
})
export class DomainService {
  domainBehaviorSubject = new BehaviorSubject<IDomain>(null);
  constructor(@Inject(WINDOW) private window: Window, private afs:AfsService) { }

  setUpDomain() {
    let myDomain = this.window.location.hostname;
    if (myDomain == 'localhost') {
      myDomain = 'greensboro.bldiamond.com'; 
    }
    this.afs.getCollection('domains', ref => ref.where('domain', '==', myDomain)).snapshotChanges().pipe(map(
      changes => {
        return changes.map(
          a => {
            const data = a.payload.doc.data() as IDomain;
            data.key = a.payload.doc.id;
            return data;
          });

      })).subscribe((domainData) => {
        if (domainData.length > 0) {
          this.domainBehaviorSubject.next(domainData[0]);
        }
      })

  }



}
