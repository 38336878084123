import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FormsModule } from '@angular/forms';
import { AdsenseModule } from 'ng2-adsense';
import { faEye, faHome, faSignInAlt, faSignOutAlt, faThumbsUp, faThumbsDown, faBan, faPlusCircle, faCalendarAlt, faEdit, faCog, faUserPlus, faTrash, faUserMinus, faUserEdit, faUserFriends, faMale, faFemale, faTimesCircle, faFile, faList, faPlus, faCheckCircle, faCircle, faSatellite, faSatelliteDish, faSearch, faSlidersH } from '@fortawesome/free-solid-svg-icons';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WINDOW_PROVIDERS } from './core/services/window.service';
import { MainService } from './core/services/main.service';
import { AfsService } from './core/services/afs.service';
import { DomainService } from './core/services/domain.service';

library.add(faEye, faHome, faSearch, faSignInAlt, faSignOutAlt, faThumbsUp, faThumbsDown, faBan, faPlusCircle, faCalendarAlt, faEdit, faCog, faUserPlus, faTrash, faUserMinus, faUserEdit, faUserFriends, faMale, faFemale, faTimesCircle, faFile, faList, faPlus, faHome, faCheckCircle, faCircle, faSatelliteDish, faSlidersH);



@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AdsenseModule.forRoot({
      adClient: 'ca-pub-5095135868057406'
    }),
    BrowserModule,
    AppRoutingModule, FormsModule, FontAwesomeModule, AngularFireModule.initializeApp(environment.firebase),
  ],
  providers: [WINDOW_PROVIDERS, MainService, AngularFireAuth,
    AngularFirestore, AfsService],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private ds: DomainService) {
    this.ds.setUpDomain()

  }
 }
