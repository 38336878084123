import { Injectable, Inject } from '@angular/core';
import { AfsService } from './afs.service';
import { WINDOW } from './window.service';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  constructor(private afs: AfsService) { }






}
